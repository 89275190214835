<template>
   <v-row>
        <v-col cols="12">
       <v-card flat outlined :disabled="!($auth.isSiteAdmin || $auth.isTenantAdmin || $auth.role.isSystemAdmin)">
         <v-card-text>
           <v-form  ref="form"
    v-model="valid"
    lazy-validation>
          <v-row>
            <v-col cols="12" md="4">
              <TextField v-model="fullname" dense :title="'ชื่อ-นามสกุล'" :rules="fullnameRules" :placeholder="'กรอกชื่อ-นามสกุล'"/>
            </v-col>
             <v-col cols="12" md="3">
             <TextField v-model="cid" :counter="13" dense :title="'หมายเลขประจำตัวประชาชน'" :rules="cidRules"  :placeholder="'หมายเลขหน้าบัตร 13 หลัก'"/>
            </v-col>
             <v-col cols="12" md="3">
             <TextField v-model="mobile" dense :title="'เบอร์ติดต่อ'" :rules="mobileRules"  :placeholder="'หมายเลขเบอร์ติดต่อ'"/>
            </v-col>
            <v-spacer/>
              <!--   <v-col cols="12" md="2">
             <TextField dense :title="'รหัสเข้าใช้งาน'" :placeholder="'รหัส 6 หลัก'"/>
            </v-col> -->
             <v-col cols="12" md="2">
                <label class="text-title-4 natural--text text--darken-4">ดำเนินการ
              <v-btn color="primary" @click="inviteCollector" :loading="loading"  block>เพิ่มข้อมูล</v-btn>
               </label>
            </v-col>
          </v-row>
           </v-form>
         </v-card-text>
       </v-card>
        </v-col>
         <v-col cols="12">
       <v-card flat outlined :disabled="!($auth.isSiteAdmin || $auth.isTenantAdmin || $auth.role.isSystemAdmin)">
         <v-card-text>
        <DataTable :items="items" :headers="headers" :loading="loading">
          <template slot="no-data">
            <div class="text-center text-title-1 natural--text text--darken-4">
              ยังไม่มีข้อมูลเจ้าหน้าที่คัดกรอง
            </div>
              <div class="text-center text-title-3 natural--text text--lighten-1 pt-2">
              กรุณาเพิ่มข้อมูลด้านบนเพื่อทำการเพิ่มเจ้าหน้าที่คัดกรอง
            </div>
          </template>
           <template v-slot:item.otp="{ item }">
             <div class="pt-3">
             <v-text-field outlined dense  :type="item.showPassword ? 'text' : 'password'" v-model="item.otp" :append-icon="item.showPassword ? 'mdi-eye' : 'mdi-eye-off'"  @click:append="item.showPassword = !item.showPassword" />
             </div>
           </template>
          <template v-slot:item.action="{ item }">
            <v-row align="center" v-if="!item.editable">
            <v-switch inset v-model="item.active" @change="patchCollector(item)" />
           <v-btn icon @click="onDelete(item)">
            <v-icon>mdi-delete</v-icon>
         </v-btn>
            </v-row>
       </template>
        </DataTable>
        <v-dialog v-model="confirmDeleteDialog" width="384" persistent>
           <ConfirmDeleteCard :title="'คุณต้องลบรายการนี้หรือไม่?'" :subtitle="''" :action="'ลบข้อมูลนี้'" @onClose="confirmDeleteDialog=false" @onConfirm="deleteSiteCollector()" />
        </v-dialog>
         </v-card-text>
       </v-card>
         </v-col>
      </v-row>
</template>

<script>
import TextField from '@/components/common/TextField'
import DataTable from '@/components/common/SubDataTable'
import ConfirmDeleteCard from '@/components/common/ConfirmDeleteCard'
import { getCollector, postCollector, patchCollector, deleteSiteCollector } from '@/api/'
export default {
  components: {
    TextField,
    DataTable,
    ConfirmDeleteCard
  },
  data () {
    return {
      headers: [
        { text: 'ชื่อ-นามสกุล', value: 'fullname', class: 'primary lighten-5' },
        { text: 'เลขประจำตัวประชาชน', value: 'cid', class: 'primary lighten-5' },
        { text: 'เบอร์ติดต่อ', value: 'id', class: 'primary lighten-5' },
        { text: 'รหัสเข้าใช้งาน', value: 'otp', class: 'primary lighten-5', width: '200px' },
        { text: 'ดำเนินการ', value: 'action', class: 'primary lighten-5' }
      ],
      items: [],
      total: null,
      loading: false,
      cid: null,
      fullname: null,
      mobile: null,
      valid: false,
      show: false,
      cidRules: [
        v => !!v || 'CID is required',
        v => (v && v.length >= 10) || 'กรุณากรอกข้อมูลให้ครับถ้วน'
      ],
      mobileRules: [
        v => !!v || 'Mobile is required',
        v => (v && v.length >= 10) || 'กรุณากรอกข้อมูลให้ครับถ้วน'
      ],
      fullnameRules: [
        v => !!v || 'Fullname is required'
      ],
      currentItem: null,
      confirmDeleteDialog: false
    }
  },
  methods: {
    onDelete (item) {
      this.currentItem = item
      this.confirmDeleteDialog = true
    },
    deleteSiteCollector () {
      this.confirmDeleteDialog = false
      const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
      deleteSiteCollector({ id: this.currentItem.id, etag: this.currentItem._etag, tenantId: tenant.tenantId, siteId: this.$route.params.siteId }, message => {
        if (message.data.code === 1) {
          this.fetchData()
        }
      }, error => {
        console.log(error)
      })
    },
    patchCollector (item) {
      const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
      patchCollector({ id: item.id, etag: item._etag, tenantId: tenant.tenantId, siteId: this.$route.params.siteId }, item, message => {
        if (message.data.code === 1) {
          this.fetchData()
          item.editable = false
        }
      }, error => {
        console.log(error)
      })
    },
    inviteCollector () {
      if (this.$auth.role) {
        if (this.$refs.form.validate()) {
          this.loading = true
          const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
          postCollector({
            tenantId: tenant.tenantId,
            siteId: this.$route.params.siteId,
            cid: this.cid,
            fullname: this.fullname,
            mobile: this.mobile
          }, message => {
            console.log(message)
            if (message.data.code === 1) {
              this.$refs.form.reset()
              this.fetchData()
            }
            this.loading = false
          }, error => {
            this.loading = false
            console.log(error)
          })
        }
      }
    },
    fetchData () {
      if (this.$auth.role) {
        this.loading = true
        const tenant = JSON.parse(sessionStorage.getItem('Tenant'))
        getCollector({
          tenantId: tenant.tenantId,
          siteId: this.$route.params.siteId,
          offset: 0,
          limit: 100
        }, message => {
          if (message.data.code === 1) {
            if (message.data.result) {
              message.data.result.items.forEach(function (element) {
                element.editable = false
                element.showPassword = false
              })
              this.items = message.data.result.items
              this.total = message.data.result.total
            } else {
              this.items = []
            }
          }
          this.loading = false
        }, error => {
          this.loading = false
          console.log(error)
        })
      }
    }
  },
  created () {
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData()
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
  }
}
</script>

<style>

</style>
